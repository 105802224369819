exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-all-clients-tsx": () => import("./../../../src/pages/all-clients.tsx" /* webpackChunkName: "component---src-pages-all-clients-tsx" */),
  "component---src-pages-apps-tasks-privacy-policy-tsx": () => import("./../../../src/pages/apps/tasks-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-apps-tasks-privacy-policy-tsx" */),
  "component---src-pages-apps-tasks-tsx": () => import("./../../../src/pages/apps/tasks.tsx" /* webpackChunkName: "component---src-pages-apps-tasks-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-blixt-tsx": () => import("./../../../src/pages/blixt.tsx" /* webpackChunkName: "component---src-pages-blixt-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-analytics-snowflake-tsx": () => import("./../../../src/pages/data-analytics/snowflake.tsx" /* webpackChunkName: "component---src-pages-data-analytics-snowflake-tsx" */),
  "component---src-pages-data-analytics-tsx": () => import("./../../../src/pages/data-analytics.tsx" /* webpackChunkName: "component---src-pages-data-analytics-tsx" */),
  "component---src-pages-data-harmonisation-tsx": () => import("./../../../src/pages/data-harmonisation.tsx" /* webpackChunkName: "component---src-pages-data-harmonisation-tsx" */),
  "component---src-pages-digital-services-tsx": () => import("./../../../src/pages/digital-services.tsx" /* webpackChunkName: "component---src-pages-digital-services-tsx" */),
  "component---src-pages-digital-tsx": () => import("./../../../src/pages/digital.tsx" /* webpackChunkName: "component---src-pages-digital-tsx" */),
  "component---src-pages-erp-tsx": () => import("./../../../src/pages/erp.tsx" /* webpackChunkName: "component---src-pages-erp-tsx" */),
  "component---src-pages-fi-tsx": () => import("./../../../src/pages/fi.tsx" /* webpackChunkName: "component---src-pages-fi-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-grad-hiring-about-tsx": () => import("./../../../src/pages/grad-hiring/about.tsx" /* webpackChunkName: "component---src-pages-grad-hiring-about-tsx" */),
  "component---src-pages-grad-hiring-careers-tsx": () => import("./../../../src/pages/grad-hiring/careers.tsx" /* webpackChunkName: "component---src-pages-grad-hiring-careers-tsx" */),
  "component---src-pages-grad-hiring-hiring-tsx": () => import("./../../../src/pages/grad-hiring/hiring.tsx" /* webpackChunkName: "component---src-pages-grad-hiring-hiring-tsx" */),
  "component---src-pages-grad-hiring-internship-tsx": () => import("./../../../src/pages/grad-hiring/internship.tsx" /* webpackChunkName: "component---src-pages-grad-hiring-internship-tsx" */),
  "component---src-pages-grad-hiring-tsx": () => import("./../../../src/pages/grad-hiring.tsx" /* webpackChunkName: "component---src-pages-grad-hiring-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infor-infor-m-3-tsx": () => import("./../../../src/pages/infor/infor-m3.tsx" /* webpackChunkName: "component---src-pages-infor-infor-m-3-tsx" */),
  "component---src-pages-infor-tsx": () => import("./../../../src/pages/infor.tsx" /* webpackChunkName: "component---src-pages-infor-tsx" */),
  "component---src-pages-integration-ivolve-tsx": () => import("./../../../src/pages/integration/ivolve.tsx" /* webpackChunkName: "component---src-pages-integration-ivolve-tsx" */),
  "component---src-pages-integration-tsx": () => import("./../../../src/pages/integration.tsx" /* webpackChunkName: "component---src-pages-integration-tsx" */),
  "component---src-pages-kronos-privacy-policy-tsx": () => import("./../../../src/pages/kronos-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-kronos-privacy-policy-tsx" */),
  "component---src-pages-lead-tsx": () => import("./../../../src/pages/lead.tsx" /* webpackChunkName: "component---src-pages-lead-tsx" */),
  "component---src-pages-microsoft-tsx": () => import("./../../../src/pages/microsoft.tsx" /* webpackChunkName: "component---src-pages-microsoft-tsx" */),
  "component---src-pages-mobility-tsx": () => import("./../../../src/pages/mobility.tsx" /* webpackChunkName: "component---src-pages-mobility-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-norway-tsx": () => import("./../../../src/pages/norway.tsx" /* webpackChunkName: "component---src-pages-norway-tsx" */),
  "component---src-pages-oresund-tsx": () => import("./../../../src/pages/oresund.tsx" /* webpackChunkName: "component---src-pages-oresund-tsx" */),
  "component---src-pages-our-expertise-tsx": () => import("./../../../src/pages/our-expertise.tsx" /* webpackChunkName: "component---src-pages-our-expertise-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sap-bydesign-tsx": () => import("./../../../src/pages/sap/bydesign.tsx" /* webpackChunkName: "component---src-pages-sap-bydesign-tsx" */),
  "component---src-pages-sap-commerce-cloud-tsx": () => import("./../../../src/pages/sap/commerce-cloud.tsx" /* webpackChunkName: "component---src-pages-sap-commerce-cloud-tsx" */),
  "component---src-pages-sap-move-to-cloud-tsx": () => import("./../../../src/pages/sap/move-to-cloud.tsx" /* webpackChunkName: "component---src-pages-sap-move-to-cloud-tsx" */),
  "component---src-pages-sap-tsx": () => import("./../../../src/pages/sap.tsx" /* webpackChunkName: "component---src-pages-sap-tsx" */),
  "component---src-pages-sap-ufm-tsx": () => import("./../../../src/pages/sap/ufm.tsx" /* webpackChunkName: "component---src-pages-sap-ufm-tsx" */),
  "component---src-pages-se-tsx": () => import("./../../../src/pages/se.tsx" /* webpackChunkName: "component---src-pages-se-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-us-tsx": () => import("./../../../src/pages/us.tsx" /* webpackChunkName: "component---src-pages-us-tsx" */),
  "component---src-templates-articles-detail-tsx": () => import("./../../../src/templates/ArticlesDetail.tsx" /* webpackChunkName: "component---src-templates-articles-detail-tsx" */),
  "component---src-templates-case-studies-detail-tsx": () => import("./../../../src/templates/CaseStudiesDetail.tsx" /* webpackChunkName: "component---src-templates-case-studies-detail-tsx" */),
  "component---src-templates-internship-detail-tsx": () => import("./../../../src/templates/InternshipDetail.tsx" /* webpackChunkName: "component---src-templates-internship-detail-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/NewsDetail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-portfolio-detail-tsx": () => import("./../../../src/templates/PortfolioDetail.tsx" /* webpackChunkName: "component---src-templates-portfolio-detail-tsx" */),
  "component---src-templates-position-detail-tsx": () => import("./../../../src/templates/PositionDetail.tsx" /* webpackChunkName: "component---src-templates-position-detail-tsx" */)
}

